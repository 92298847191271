<template>
  <div>
    <label
      >{{ field.name }}
      <span class="text-danger" v-if="field.required">*</span></label
    >
    <b-form-select
      :disabled="statusCampaign != 0 && field.field_profile_type_id != 0"
      v-model="field.value"
      :class="['input-custom', { error: field.validation }]"
    >
      <template #first>
        <b-form-select-option v-if="field.value == null" :value="null" disabled
          >-- กรุณาเลือก --</b-form-select-option
        >
        <b-form-select-option v-else-if="field.value == ''" value="" disabled
          >-- กรุณาเลือก --</b-form-select-option
        >
      </template>
      <b-form-select-option
        v-for="(dropdown, i) in field.field_choices"
        :key="i"
        :value="dropdown.name"
        >{{ dropdown.name }}</b-form-select-option
      >
    </b-form-select>
    <div v-if="field.validation">
      <span class="text-error">กรุณาเลือกข้อมูล</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FieldDropdown",
  props: {
    field: {
      required: true,
      type: Object,
    },
    statusCampaign: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-custom.error {
  border-color: red !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
