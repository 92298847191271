<template>
  <div>
    <div>
      <div class="d-flex align-items-center">
        <label for="">{{ label }} </label>
        <div v-if="hasedit">
          <b-button
            variant="link"
            @click="$emit('handleClick')"
            class="text-warning px-1 py-0"
          >
            <font-awesome-icon icon="pencil-alt" title="Edit" />
          </b-button>
        </div>
      </div>
      <!-- <span v-if="firstname">
        <InputText v-model="firstname" class="col-md-3" />
        <InputText v-model="lastname" class="col-md-3" />
      </span> -->
      <router-link v-if="routeLink" :to="routeLink">
        <u>{{ value }}</u>
      </router-link>
      <div v-else>{{ value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: {
      required: true,
    },
    routeLink: {
      required: false,
    },
    hasedit: {
      required: false,
      default: false,
    },
  },
};
</script>

<style></style>
